<template>
    <div class="aplayz_modal">
        <div class="aplayz_modal_confirm_wrap">
            <h3 class="modal_contents" v-html="contents"></h3>

            <ul class="confirm_btn_group dp_flex">
                <li class="confirm_btngroup_wrap">
                    <button class="confirm_btn" @click="$emit('cancel-click')">아니오</button>
                </li>
                <li class="confirm_btngroup_wrap">
                    <button class="confirm_btn confirm" @click="$emit('confirm-click')">예</button>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'ConfirmYesNo',
  props: {
    contents: {
      type: String,
      default: '해당 큐레이션을 삭제하시겠습니까?',
      required: false
    }
  },
  emits: ['cancel-click', 'confirm-click'],
  data () {
    return {};
  },

  mounted () {},

  methods: {}
});
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/common/comfirmyesno.css"></style>
